import { styled } from 'Theme/stitches.config';
import RadioButton from 'DesignComponents/Atoms/OLD/RadioButton/RadioButton';
import { useEffect } from 'react';
import { useUiState } from 'Shared/Providers/UiState/UiStateProvider';
import AnimatedIcon from 'DesignSystem/Icons/ExtenderIcons/AnimatedIcon';
import LoadingCircle from 'DesignComponents/Atoms/Loaders/LoadingCircle';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import ShippingCountryModel from 'Models/Shipping/ShippingCountryModel.interface';
import { API_ROOT_PATH } from 'Shared/Constants/route';
import useSWR from 'swr';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import { GetShippingDestination } from './ShippingDestination';

export const GET_COUNTRIES_URL =
  API_ROOT_PATH + '/shipment/getavailablecountries';

const CountryPickerModalContainer = () => {
  const currentShippingDestination = GetShippingDestination();
  const { country, setCountry } = useUiState();

  const {
    commonLabels: { switchDeliveryCountryInfo, availableShippingDestinations },
    cartLabels: { yourCart },
    errorLabels: { errorFetchingData },
  } = useTranslationData();

  useEffect(() => {
    setCountry(currentShippingDestination);
  }, []);

  const fetcher = (url: string) => fetch(url).then((res) => res.json());
  const { data, error } = useSWR(GET_COUNTRIES_URL, fetcher);

  if (error) return <Error>{errorFetchingData}</Error>;

  if (!data) {
    return (
      <StyledLoadingCircle>
        <AnimatedIcon animation={'spinAnimation'}>
          <LoadingCircle />
        </AnimatedIcon>
      </StyledLoadingCircle>
    );
  }

  // data is the response from the API
  const countries: ShippingCountryModel[] = data.availableShippingDestinations;

  return (
    <CountryPickerWrapper>
      <InfoText>
        <Heading tag="h4" size="s" css={{ marginBottom: '$s50' }}>
          {yourCart}
        </Heading>
        <Description>{switchDeliveryCountryInfo}</Description>
      </InfoText>
      <div aria-label={availableShippingDestinations}>
        {countries.map((c, index) => (
          <Country key={index}>
            <RadioButton
              isChecked={c.code === country.code}
              text={c.name}
              value={c.code}
              groupName="country"
              onChange={() => setCountry(c)}
            />
          </Country>
        ))}
      </div>
    </CountryPickerWrapper>
  );
};

export default CountryPickerModalContainer;

const Description = styled('div', {
  fontSize: '$fontSize75',
  lineHeight: '$lh24',
});

const CountryPickerWrapper = styled('div', {
  mt: 6,
});

const Error = styled('div', {
  mt: 6,
  mb: 6,
});

const InfoText = styled('div', {
  mt: 6,
  mb: 6,
  maxWidth: '80ch',
});

const Country = styled('p', {
  width: 'fit-content',
  p: 2,
  ':has(input:focus)': {
    outline: '2px solid $focusBorder',
    outlineOffset: '2px',
  },
});

const StyledLoadingCircle = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  mt: 10,
});
